<template>
	<v-app class="v-app-main-wrapper shifl-app-main-wrapper" id="inspire" v-resize="onResize">
		<special-dialog
			:title="specialNotificationTitle"
			:message="specialNotificationMessage"
			:show="showSpecialDialog"
			@close="closeSpecialDialog" />

		<v-navigation-drawer
			:style="isMakePaymentActive ? 'display:none': ''"
			class="shifl-navigation-drawer"
			ref="scrollable-drawer-content"
			v-model="drawer"
			app
			v-if="getUserToken !== '' && getUser !== null && !hideSidebarAndHeader && !isShowDrawer">
			<DrawerMenu v-bind:isMobile="isMobile" />
		</v-navigation-drawer>

		<ul v-if="isTablet && !hideSidebarAndHeader"
			class="tablet-header-menu"
			:class="getUserToken !== '' && getUser !== null && !hideSidebarAndHeader ? 'authenticated' : '' ">
			<li v-if="!drawer">
				<v-app-bar-nav-icon @click="drawer = !drawer" color="white">
				</v-app-bar-nav-icon>
			</li>

			<li style="width: 36px; height: 40px;" class="d-flex align-center justify-center mt-1"
				v-if="drawer"
				@click="drawer = !drawer">
				<svg
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M16.4412 3.55945C16.7419 3.86008 16.7419 4.34748 16.4412 4.6481L11.0887 10.0006L16.4412 15.3532C16.7419 15.6538 16.7419 16.1412 16.4412 16.4418C16.1406 16.7425 15.6532 16.7425 15.3526 16.4418L10 11.0893L4.64749 16.4418C4.34687 16.7425 3.85946 16.7425 3.55884 16.4418C3.25822 16.1412 3.25822 15.6538 3.55884 15.3532L8.91139 10.0006L3.55884 4.6481C3.25822 4.34748 3.25822 3.86008 3.55884 3.55945C3.85947 3.25883 4.34687 3.25883 4.6475 3.55945L10 8.912L15.3526 3.55945C15.6532 3.25883 16.1406 3.25883 16.4412 3.55945Z"
						fill="white"
					/>
				</svg>
			</li>

			<li>
				<div class="logo">
					<router-link to="/shipments" class="shipment-logo">
						<img src="@/assets/images/logo.png" alt="" />
					</router-link>
				</div>
			</li>
		</ul>

		<v-app-bar
			app
			height="64px"
			v-bind:style="{ background: activeColor, 'left': isMakePaymentActive ? '0 !important' : 'auto' }"
			v-if="getUserToken !== '' && getUser !== null && !isMobile && !hideSidebarAndHeader && !isShowDrawer">

			<v-toolbar-title>
				<div class="forFelx" :class="isMobile ? 'mobile' : 'desktop'">
					<SearchGetHelp v-bind:isMobile="isMobile" />
					<div class="share-feedback-btn mr-5" @click="openFeedback = true">
						<span class="share-feedback-text">
							Share Feedback
						</span>
					</div>
					<div class="d-flex align-center">
						<notifications :isMobile="isMobile" />
						<Notification v-bind:isMobile="isMobile" />
					</div>

					<!-- <div class="d-flex align-center">
						<div class="mr-3">
						<button
							@click="openNotifications()"
							style="position: relative; margin-top: 10px">
							<img src="@/assets/icons/bell.svg" />
							<div
							v-if="getUnreadMessages > 0"
							:class="getUnreadMessages > 99 ? 'large-div' : ''"
							class="notifi-count">
							<p class="messages-count"
								:class="getUnreadMessages > 99 ? 'mess-count-la' : ''">
								{{ getUnreadMessages }}
							</p>
							</div>
						</button>
						</div>
						<Notification v-bind:isMobile="isMobile" />
					</div> -->
				</div>
			</v-toolbar-title>
		</v-app-bar>

		<nav class="header-menu" id="header-id"
			v-if="getUserToken !== '' && getUser !== null && isMobile && !hideSidebarAndHeader">
			<span class="extend-search" id="search-icon-extend" v-if="isInputExpanded">
				<img src="@/assets/images/search-icon.svg" alt="" />
				<button v-if="searchData !== ''"
					class="close-btn"
					@click.stop="clearInput">
					<img src="@/assets/images/close.svg" alt="" width="18px" />
				</button>
			</span>

			<ul class="search-wrapper">
				<li v-if="!drawer">
					<v-app-bar-nav-icon v-if="isMobile && !isInputExpandedNew"
						@click="onClickDrawer"
						color="white">
					</v-app-bar-nav-icon>
					<button v-if="isInputExpandedNew" @click="gotoMainPage" class="shipment-logo">
						<img src="@/assets/icons/back.svg" alt="" />
					</button>
				</li>

				<li style="width:36px;height: 40px;" v-if="drawer" @click="drawer = !drawer">
					<svg
						width="36"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M16.4412 3.55945C16.7419 3.86008 16.7419 4.34748 16.4412 4.6481L11.0887 10.0006L16.4412 15.3532C16.7419 15.6538 16.7419 16.1412 16.4412 16.4418C16.1406 16.7425 15.6532 16.7425 15.3526 16.4418L10 11.0893L4.64749 16.4418C4.34687 16.7425 3.85946 16.7425 3.55884 16.4418C3.25822 16.1412 3.25822 15.6538 3.55884 15.3532L8.91139 10.0006L3.55884 4.6481C3.25822 4.34748 3.25822 3.86008 3.55884 3.55945C3.85947 3.25883 4.34687 3.25883 4.6475 3.55945L10 8.912L15.3526 3.55945C15.6532 3.25883 16.1406 3.25883 16.4412 3.55945Z"
							fill="white"
						/>
					</svg>
				</li>

				<li :style="isInputExpandedNew ? 'width:0' : ''">
					<div :style="isInputExpandedNew ? 'display:none' : ''" class="logo">
						<button icon @click="onClickOutside" class="shipment-logo">
							<img src="@/assets/images/logo.png" alt="" />
						</button>
					</div>
				</li>

				<li class="search-wrap">
					<div :style="isInputExpandedNew ? 'display:none' : ''" class="mr-0 pr-0">
						<notifications :isMobile="isMobile" />
						<!-- <button @click="openNotifications()" style="position: relative; margin-top: 10px">
							<img src="@/assets/icons/bellWhite.svg" />
							<div v-if="getUnreadMessages > 0"
								:class="getUnreadMessages > 99 ? 'large-div' : ''"
								class="notifi-count">
								<p class="messages-count"
									:class="getUnreadMessages > 99 ? 'mess-count-la' : ''">
									{{ getUnreadMessages }}
								</p>
							</div>
						</button> -->
					</div>
					<!-- <Notification class="m-0 p-0" v-bind:isMobile="isMobile" /> -->

					<div :class="isInputExpandedNew ? 'expanded-top' : ''" class="searchHelpMobile">
						<img width="20px" height="20px"
							v-if="!isInputExpandedNew"
							class="mobile-search-icon"
							src="@/assets/images/search-icon-white.svg"
							alt="" />

						<v-card class="transparent elevation-0"
							v-click-outside="{ handler: onClickOutside, includeNew, }"
							@click="inputClick">

							<div>
								<v-card class=" included flex transparent elevation-0">
									<v-autocomplete
										v-if="isInputExpandedNew"
										class="dropdown-mobile-search"
										:class="selectedSearchType === 'All' || selectedSearchType == 'PO' ? 'dropdown-mobile-search-forAll' : selectedSearchType === 'MBL' ? 'dropdown-mobile-search-forMBL' : ''"
										style="color: black;"
										filled
										append-icon="mdi-chevron-down"
										:hide-details="true"
										dense
										@change="onAutocompleteChange" 
										autocomplete="off"
										:items="searchTypeDropDown"
										v-model="selectedSearchType"
										item-text="name"
										item-value="id">
										<template v-slot:selection="{ item }">
												<span
													style="font-size: 14px;color: #253041;padding-left: 2px;"
												>
													{{ item === 'Custom Reference' ? 'Cust. Ref#':item }}
												</span>
										</template>
										<template v-slot:item="{ item, on, attrs }">
											<v-list-item
												active-class="global-search-for-bg-active-mobile"
												v-on="on"
												v-bind="attrs"
												@click.stop="setActiveTrue">
												<v-list-item-content>
													<v-list-item-title>
														<p class="name mb-1 font-medium">
															{{ item }}
														</p>
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</template>
									</v-autocomplete>

									<input
										id="search-input"
										ref="refInput"
										v-model.trim="searchData"
										class="global-search-text-autocomplete"
										:class="(selectedSearchType === 'All' || selectedSearchType == 'PO') && isInputExpandedNew ? 'expendedwidth' : selectedSearchType === 'MBL' && isInputExpandedNew ? 'expendedwidthMBL' : isInputExpandedNew ? 'expanded':''"
										type="text"
										:placeholder="isInputExpandedNew ? 'Search anything...' : ''"
										autocomplete="off"
										@input="handleSearch"
										@click="showSearchView" 
									/>
									<br />
									<button v-if="searchData !== ''" style="position:absolute;right:6px;top:12px"
										@click.stop="clearInput">
										<img
											src="@/assets/images/close.svg"
											alt=""
											width="18px" />
									</button>
								</v-card>
							</div>
						</v-card>
						<!-- @keyup.stop="handleSearch" -->
					</div>
				</li>
			</ul>

			<div class="search-results" :class="isOpen ? 'open-dropdown' : 'close-dropdown'">
				<ul class="loading-search-wrapper" v-if="masterSearchLoading">
					<div class="loading-search">
						<v-row no-gutters>
                            <v-col class="pa-0 ma-0" cols="12" v-for="n in 8" :key="n">
                                <v-skeleton-loader
                                    class="px-4"
                                    height="90"
                                    type="table-cell,list-item-avatar-two-line">
                                </v-skeleton-loader>
                                <v-divider style="border-color: #EBF2F5 !important;"></v-divider>
                            </v-col>
                        </v-row>
					</div>
				</ul>

				<!-- PO SEARCH GLOBAL -->
				<ul class="has-data" v-if="isPoResultShow && !masterSearchLoading && poResults.length > 0 && searchData !== ''">
					<div v-if="typeof poResults !== 'undefined' && poResults.length > 0">
						<p class="dropdown-title">Purchase Orders</p>
						<div v-for="(data, index) in poResults" :key="index">
							<li class="dropdown-data">
								<router-link :to="`/po-details/${data.id}`">
									<img src="./assets/images/search-shipment-icon.svg"
										alt="" />
									<div>
										<p class="data-ref">
											{{ "PO # " + data.po_number }}
										</p>
										<p class="data-location">
											{{
												typeof data.status !== "undefined" &&
												data.status !== "" &&
												data.status !== null
													? ucWords(data.status)
													: "No status"
											}}
										</p>
									</div>
								</router-link>
							</li>
						</div>
					</div>
				</ul> 

				<!-- SHIPMENTS SEARCH GLOBAL -->
				<ul class="has-data" v-if="isShowSearchResults && !masterSearchLoading && searchResults !== null && searchData !== ''">
					<div v-if="typeof searchResults.shipments !== 'undefined' && searchResults.shipments !== null &&
						searchResults.shipments.length > 0">
						<p class="dropdown-title">Shipments</p>

						<div v-for="(data, index) in searchResults.shipments" :key="index">
							<li class="dropdown-data" v-on:click="goToShipmentDetails(data)">
								<router-link :to="`/shipment/${data.id}`">
									<img src="./assets/images/search-shipment-icon.svg"
										alt=""/>

									<div>
										<p class="data-ref">
											<span class="data-ref" v-html="highlightText(data.shifl_ref)"></span>
										</p>
										<p class="data-location">
											{{ data.location_from_name !== "undefined" &&
												data.location_from_name !== null
												? `${data.location_from_name}
												(${convertUTCDatetoLocal(
													data.etd
												)}) - ` : "not specified"
											}}

											{{ data.location_to_name !== "undefined" &&
												data.location_to_name !== null
												? `${data.location_to_name}
												(${convertUTCDatetoLocal(
													data.eta
												)}) - ` : "not specified"
											}}
										</p>
									</div>
								</router-link>
							</li>
						</div>
					</div>

					<div v-if="typeof searchResults.purchase_order !== 'undefined' && searchResults.purchase_order !== null &&
							searchResults.purchase_order.length > 0">
						<p class="dropdown-title">Purchase Orders</p>
						<div v-for="(data, index) in searchResults.purchase_order" :key="index">
							<li class="dropdown-data">
								<router-link :to="`/po-details/${data.id}`">
									<img src="./assets/images/search-po-orders-icon.svg"
										alt=""/>
									<div>
										<p class="data-ref">
											{{ "PO # " }}
											<span class="data-ref" v-html="highlightText(data.po_number)"></span>
										</p>
										<p class="data-location">
											{{
												`${convertUTCDatetoLocal(
													data.cargo_ready_date
												)} * ${data.supplier_name}`
											}}
										</p>
									</div>
								</router-link>
							</li>
						</div>
					</div>

					<div v-if="typeof searchResults.suppliers !== 'undefined' && searchResults.suppliers !== null &&
							searchResults.suppliers.length > 0">
						<p class="dropdown-title">Suppliers</p>

						<div v-for="(data, index) in searchResults.suppliers" :key="index">
							<li class="dropdown-data">
								<router-link :to="`/contact?supName=${data.display_name}`">
									<img src="./assets/images/search-supplier-icon.svg"
										alt=""
										width="35px"
										height="35px" />
									<div>
										<p class="data-ref">
											{{ data.display_name }}
										</p>
										<p class="data-location">
											{{ data.phone }}
										</p>
									</div>
								</router-link>
							</li>
						</div>
					</div>

					<div v-if="typeof searchResults.products !== 'undefined' && searchResults.products !== null &&
							searchResults.products.length > 0">
						<p class="dropdown-title">Items</p>

						<div v-for="(data, index) in searchResults.products" :key="index">
							<li class="dropdown-data" v-on:click="openProductViewDialog(data)">
								<router-link :to="`#`">
									<img src="./assets/images/empty-product-icon.svg"
										alt=""
										width="35px"
										height="35px"
										class="product-img" />
									<div>
										<p class="data-ref">
											{{ "SKU #" + data.category_name }}
										</p>
										<p class="data-location">
											{{ data.name }}
										</p>
									</div>
								</router-link>
							</li>
						</div>
					</div>

					<div v-if="typeof searchResults.documents !== 'undefined' && searchResults.documents !== null &&
							searchResults.documents.length > 0">
						<p class="dropdown-title">Documents</p>

						<div v-for="(data, index) in searchResults.documents" :key="index">
							<li class="dropdown-data">
								<button @click="previewPdfFile(data.path)">
									<img src="./assets/images/search-document-icon.svg"
										alt=""
										width="35px"
										height="35px"/>
									<div>
										<p class="data-ref">{{ data.type }}</p>
										<p class="data-location">
											{{
												`${convertUTCDatetoLocal(
													data.updated_at
												)} * ${data.name}`
											}}
										</p>
									</div>
								</button>
							</li>
						</div>
					</div>
				</ul>

				<!-- SEARCH GLOBAL NO DATA -->
				<!-- FOR Click on Search Results -->
				<ul class="search-any-data shipments" v-if="isShowSearchView && searchData == ''">
					<div class="search-any-data-view">
						<div class="search-any-data-img">
							<img 
								src="./assets/images/search-data-icon.svg"
								alt=""
								class="search-anything" 
							/>
						</div>
						<h4>Search Anything</h4>
						<p class="mb-0">
							Start typing your search term (e.g. MBL number, shipment reference) or,
							 Select a category to narrow down your results.
						</p>
					</div>
				</ul>

				<ul class="no-match-data shipments" v-if="isShowNoMatchView && !masterSearchLoading && !isShowSearchView && searchData !== '' && !isShowSearchResults">
					<div class="search-any-data-view">
						<div class="search-any-data-img">
						<img src="./assets/icons/no-match-data.svg"
							alt=""
							srcset="" />
						</div>
						<h4>No Match Found</h4>
						<p class="mb-0">
							We couldn’t find any result that matches your
							search. Can you please check the spelling or try different
							search?
						</p>
					</div>
				</ul>
			</div>
		</nav>

		<v-main :style="isMakePaymentActive ? 'padding-left:0 !important':isShowDrawer ? 'padding:0 !important' : ''" :class="getUserToken !== '' && getUser !== null && !hideSidebarAndHeader ? 'authenticated' : ''">
			<router-view></router-view>
			<div v-if="getAddOverlay" :class="getAddOverlay ? 'add-overlay-to-overall-page' : ''"></div>
		</v-main>

		<ProductViewDialog
			:editedItemData.sync="editedProductItem"
			:dialogViewData.sync="dialogViewProduct"
			:isMobile="isMobile"
			:categoryLists="categoryListData"
			@editItem="editProduct"
			@deleteItem="deleteProductItem"
			@close="closeProductViewDialog" />

		<ProductAddDialog
			:dialog.sync="dialogAddProduct"
			:editedIndex.sync="editedIndexProduct"
			:defaultItem.sync="defaultProductItem"
			:editedItem.sync="editedProductItem"
			:categoryLists="categoryListData"
			@close="closeProduct"
			@setToDefault="setToDefault"
			:isMobile="isMobile"
			:isInventoryPage="false"
			:isWarehouse3PL="false"
			:isWarehouse3PLProvider="false" />

		<DeleteDialog
			:dialogData.sync="dialogDeleteProduct"
			:editedItemData.sync="currentProductToDelete"
			:editedIndexWarehouse.sync="editedIndexProduct"
			:defaultItemWarehouse.sync="defaultProductItem"
			@delete="deleteProductConfirm"
			@close="closeDeleteProduct"
			fromComponent="products"
			:loadingDelete="getDeleteProductLoading"
			componentName="Products" />

		<FeedbackDialog
			:openFeedback="openFeedback"
			@closeDialog="openFeedback = false" />
	</v-app>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import DrawerMenu from "./components/DrawerMenu.vue";
import SearchGetHelp from "./components/SearchGetHelp.vue";
import Notification from "./components/Notification.vue";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import ProductViewDialog from "./components/ProductComponents/Dialog/ProductViewDialog.vue";
import ProductAddDialog from "./components/ProductComponents/Dialog/ProductAddDialog.vue";
import DeleteDialog from "./components/Dialog/DeleteDialog.vue";
import globalMethods from "./utils/globalMethods";
import { getMessaging, getToken } from "firebase/messaging";
import firebaseApp from "./utils/firebase";
import SpecialDialog from "./components/Dialog/SpecialDialog";
import {
	connectToPONotificationChannel,
	leavePONotificationChannel,
} from "@/configs/poEcho";
import FeedbackDialog from "@/components/FeedbackDialog.vue";
import store from "@/store";
import Notifications from "@/components/Notifications/Notifications";
var cancel;
var CancelToken = axios.CancelToken;
var messaging = null;
import jQuery from "jquery";

import { connectToCentralNotificationChannel, leaveCentralNotificationChannel, } from "@/configs/centralEcho";

export default {
	name: "App",
	components: {
		DrawerMenu,
		SearchGetHelp,
		Notification,
		ProductViewDialog,
		ProductAddDialog,
		SpecialDialog,
		DeleteDialog,
		FeedbackDialog,
		Notifications,
	},
	created() {
		const currentPath = store.getters["page/getCurrentPath"];
		if (_.get(currentPath, "currentPathName") == "BookingRequestForm") {
			axios
				.post(`generate-invite-token`, {
					tokenKey: _.get(currentPath, "token"),
				})
				.then((response) => {
					localStorage.setItem(
						"setBookingInviteToken",
						response.data.data
					);
					console.log("App.vue");
					this.fetchUser();
				})
				.catch((error) => {
					console.log("error", error);
				});
			axios.interceptors.request.use(function(config) {
				const token =
					"Bearer " + localStorage.getItem("setBookingInviteToken");
				config.headers.Authorization = token;
				return config;
			});
		} else {
			localStorage.removeItem("setBookingInviteToken");
			axios.interceptors.request.use(function(config) {
				const token = "Bearer " + store.getters.getUserToken;
				config.headers.Authorization = token;
				return config;
			});
			this.fetchUser();
		}
	},
	data: () => ({
		isShowDrawer :false,
		drawer: null,
		notificationDailog: false,
		isMobile: false,
		hasSetNotificationToken: false,
		poResults: [],
		windowTabShow: true,
		activeColor: "white",
		searchResults: {
			customers: null,
			documents: null,
			products: null,
			purchase_order: null,
			shipments: null,
			suppliers: null,
		},
		specialNotificationTitle: "Special Notification",
		specialNotificationMessage:
			"Refreshing the page in 5 seconds in order to update changes in your account.",
		noResults: false,
		searching: false,
		searchData: "",
		isOpen: false,
		isInputExpanded: false,
		isInputExpandedNew: false,
		isTablet: false,
		currentWidth: window.innerWidth,
		productsSearchResults: [],
		productsData: [],
		productsCurrentPage: 1,
		dialogViewProduct: false,
		dialogAddProduct: false,
		dialogDeleteProduct: false,
		editedProductItem: {
			sku: null,
			name: "",
			category_id: null,
			description: "",
			units_per_carton: "",
			image: null,
			classification_code: "",
			additional_classification_code: "",
			duty_rate: "",
			unit_price: 0,
			upc_number: "",
			carton_upc: "",
			is_for_classification_code: 1,
			userClassification: 0,
			country_from: "",
			country_to: "",
			product_classification_description: "",
			carton_dimensions: {
				l: 0,
				w: 0,
				h: 0,
				uom: "cm",
			},
			unit_dimensions: {
				l: 0,
				w: 0,
				h: 0,
				uom: "cm",
			},
			unit_weight: {
				value: 0,
				unit: "kg",
			},
		},
		defaultProductItem: {
			sku: null,
			name: "",
			category_id: null,
			description: "",
			units_per_carton: "",
			image: null,
			classification_code: "",
			additional_classification_code: "",
			duty_rate: 0,
			unit_price: 0,
			upc_number: "",
			carton_upc: "",
			is_for_classification_code: 1,
			userClassification: 0,
			country_from: "",
			country_to: "",
			product_classification_description: "",
			carton_dimensions: {
				l: 0,
				w: 0,
				h: 0,
				uom: "cm",
			},
			unit_dimensions: {
				l: 0,
				w: 0,
				h: 0,
				uom: "cm",
			},
			unit_weight: {
				value: 0,
				unit: "kg",
			},
		},
		currentProductToDelete: null,
		categoryListData: [],
		editedIndexProduct: 0,
		openFeedback: false,
		hideSidebarAndHeaderPages: [
			"BookingRequestForm",
			"BookingRequestSubmitted",
		],
		isInputExpandedNewActive: false,
		selectedSearchType: "All",
		searchTypeDropDown: [
			"All",
			"Reference",
			"MBL",
			"Custom Reference",
			"Container",
			"PO",
			"Shipper"
		],
		searchClosed: true,
		isShowSearchView: false,
		isShowNoMatchView: false,
		isShowSearchResults: false,
		isMakePaymentActive:false,
		isTruckingTool: false
	}),
	watch: {
		getUser(newUserValue) {
			if (newUserValue) {
				const userId = JSON.parse(newUserValue).id;
				const custId = JSON.parse(newUserValue).default_customer_id; // get current customer id
				connectToPONotificationChannel(userId);
				connectToCentralNotificationChannel(custId);
				this.companyDetail();
			} else { 
				leavePONotificationChannel();
				leaveCentralNotificationChannel();
			}
		},
		'$route' (to) {
			if(to.name == 'MakePayment'){
				this.isMakePaymentActive = true
			}else{
				this.isMakePaymentActive = false
			}

			if (to.name === 'Tool') {
				this.isTruckingTool = true;
			} else {
				this.isTruckingTool = false;
			}
		},
		isTruckingTool(nv, ov) {
			if (nv !== ov) {
				if (nv) {
					let token = this.$route.params.token;
					if (token) {
						let data = {
							token,
							emptyReturnedDays: 15
						}
						this.fetchTruckingContainersLogout(data);
					}
				}
			}
		}
	},
	methods: {
		...mapActions({
			logout: "logout",
			fetchUser: "fetchUser",
			fetchMasterSearch: "fetchMasterSearch",
			fetchNewShipmentDetails: "shipmentDetailsV2/fetchNewShipmentDetails",
			setMasterSearchLoading: "setMasterSearchLoading",
			fetchProducts: "products/fetchProducts",
			deleteProduct: "products/deleteProduct",
			updateNotificationToken: "updateNotificationToken",
			setAllOutboundProductsLists: 'outbound/setAllOutboundProductsLists',
			setOutboundFilteredVal:'outbound/setOutboundFilteredVal',
			setAllInboundProductsLists: 'inbound/setAllInboundProductsLists',
			fetchTruckingContainersLogout: "fetchTruckingContainersLogout"
		}),
		...mapActions("notificationNew", [
			"connectToNotificationChannel",
			"getNotificationFromApi",
			"getArchivedMessages",
		]),
		...mapMutations("notificationNew", [
			"SET_CUSTOMER",
			"SET_GENRAL_LOADER",
			"SET_OLD_CUSTOMER_ID"
		]),

		...globalMethods,
		previewPdfFile(pic){
            //let imageUrl = 'https://staging.shifl.com/'
            //let file = ''
            //if (pic !== 'undefined' && pic !== null) {
            //    if (pic.includes(imageUrl) !== 'undefined' && !pic.includes(imageUrl)) {
            //        file = imageUrl + pic
            //        file
            //    } else {
            //        file = pic
            //    }
            //}

			const downloadUrl = `${this.getBetaUrl}download?link=${encodeURIComponent(pic)}`;
            window.open(downloadUrl, '_blank');

            // var fileURL = window.URL.createObjectURL(pic);
            // window.open(fileURL);
            
        },
		closeDialogNotifi(val) {
			this.notificationDailog = val;
		},
		gotoMainPage(){
			if(this.$route.name == 'Shipment Details'){
				this.$router.push({name:"Shipments"})
			}else if(this.$route.name == 'PO Details'){
				this.$router.push({name:"POs"})
			}else if(this.$route.name == 'SalesOrders Details'){
				this.$router.push({name:"SalesOrders"})
			}else if(this.$route.name == 'ManageCategories'){
				this.$router.push({name:"Products"})
			}else if(this.$route.name == 'Inventory Inbound View'){
				this.$store.dispatch("page/setCurrentInventoryTab", 3)
				this.setAllInboundProductsLists([])
				this.$router.push({name:"Inventory", query: { tab: 'Inbound' }})
			}else if(this.$route.name == 'Inventory Outbound View'){
				this.$store.dispatch("page/setCurrentInventoryTab", 4)
				this.setAllOutboundProductsLists([])
				this.setOutboundFilteredVal([])
				this.$router.push({name:"Inventory",query: { tab: 'Outbound' }})
			}else if(this.$route.name == 'Delivery Location View'){
				this.$router.push({name:"Company Profile"})
			}
			console.log(this.$route.name)
		},
		ucWords(str) {
			return (str + "").replace(/^([a-z])|\s+([a-z])/g, function($1) {
				return $1.toUpperCase();
			});
		},
		highlightText(item) {
			if (item == null) {
				item = "not specified";
			} else {
				if (this.searchData && item.toLowerCase().includes(this.searchData.toLowerCase())) {
					const regex = new RegExp(this.searchData, "gi");
					return item.replace(
						regex,
						(match) => `<span class="highlight">${match}</span>`
					);
				}
			}
			return item;
		},
		convertUTCDatetoLocal(date) {
			return date !== null ? moment.utc(date).format("MM/DD/YYYY") : "not specified";
		},
		openNotifications() {
			this.notificationDailog = true;
		},
		_processMessaging() {
			if (!this.hasSetNotificationToken) {
				this.hasSetNotificationToken = true;
				this._getMessaging();
			}
		},
		_handleVisibilityChange() {
			if (document.visibilityState === "hidden") {
				this.windowTabShow = false;
			} else {
				if (!this.windowTabShow) {
					this.windowTabShow = true;
					this.$store.dispatch("checkCurrentAccount");
				}
			}
		},
		closeSpecialDialog() {
			this.$store.dispatch("closeSpecialDialog");
		},
		_getMessaging() {
			//configure messaging
			messaging = getMessaging(firebaseApp);

			//event handler for receiving push notification
			messaging.onMessageHandler = () => {
				this.$store.dispatch("openSpecialDialog");

				setTimeout(() => {
					let currentUrl = window.location.pathname;
					window.location.href = currentUrl;
				}, 5000);
			};

			//retrieve token
			getToken(messaging, {
				vapidKey:
					"BCgjgo7bH1LxtAyIdHgBxcQO7kSTUkHfiNWPF8tRSwBSckmUPSJg2te9Xqg91gOnEzxAI7jUKosruJHTiWeMlHo",
			})
			.then((currentToken) => {
				//register token to the server
				if (currentToken)
					this.updateNotificationToken(currentToken);
			})
			.catch((err) => {
				console.log("An error occurred while retrieving token.", err);
			});
		},
		onResize() {
			if (window.innerWidth < 1024) {
				this.isMobile = true;
				this.activeColor = "#0171A1";
			} else {
				this.isMobile = false;
				this.activeColor = "white";
			}

			if (window.innerWidth > 1023 && window.innerWidth < 1201) {
				this.isTablet = true;
			} else {
				this.isTablet = false;
			}
		},
		inputClick() {
			this.isInputExpandedNew = true;
			if(this.isInputExpandedNew && this.searchData == ''){
				this.isShowSearchView = true;
				this.isShowNoMatchView = false;
				this.isShowSearchResults = false;
			}
			if(this.searchData !== '' && !this.isShowSearchView && !this.isShowSearchResults && !this.isShowNoMatchView && !this.masterSearchLoading){
				this.apiCall(this.searchData)
			}
			//this.isShowNoMatchView = false;
			//this.isShowSearchResults = false;
		},
		clearInput() {
			setTimeout(() => {
				this.searching = false;
				if(!this.isShowSearchResults && !this.isShowNoMatchView && !this.masterSearchLoading){
					this.searchData = ""
                    this.searchResults = {
					customers: null,
					documents: null,
					products: null,
					purchase_order: null,
					shipments: null,
					suppliers: null,
				}
				this.searchData = ""
				this.isOpen = false;
				this.isInputExpandedNew = true;
				return
                }

				(this.searchResults = {
					customers: null,
					documents: null,
					products: null,
					purchase_order: null,
					shipments: null,
					suppliers: null,
				}),
					(this.searchData = "");
				this.isOpen = false;
				this.isInputExpandedNew = true;
				document.getElementById("search-input").focus();
			}, 1);
		},
		clear() {
			this.searching = false;
			(this.searchResults = {
				customers: null,
				documents: null,
				products: null,
				purchase_order: null,
				shipments: null,
				suppliers: null,
			}),
			this.searchData = "";
			//this.isOpen = false;
			this.isShowNoMatchView = false
            this.isShowSearchView = true
		},
		pgtDebounce(func, delay) {
			let debounceTimer;

			return function() {
				console.log("debouncing call..");
				const context = this;
				const args = arguments;
				clearTimeout(debounceTimer);
				debounceTimer = setTimeout(
					() => func.apply(context, args),
					delay
				);
				console.log("..done");
			};
		},
		handleSearch: _.debounce(function() {
			this.preApiCall();
		}, 900),
		preApiCall() {
			if (cancel !== undefined) {
				cancel("cancel_previous_request");
				this.isShowNoMatchView = false
                this.isShowSearchView = true
				if(this.searchData == ''){
                    this.isShowSearchResults =false
                }
				this.isOpen = true
				console.log("cancelled");
			}

			this.apiCall(this.searchData);
		},
		async apiCall(searchData) {
			this.isOpen = true;
			if (searchData !== "undefined" &&
				this.searchData !== undefined &&
				searchData !== "") {
				let passedData = {};

				passedData = {
					method: "GET",
					url: "/global-search",
					cancelToken: new CancelToken(function executor(c) {
						cancel = c;
					}),
					params: {
						search_type: this.selectedSearchType.replace(/ /g, "_"),
						search_text: searchData,
					},
				};
				
				try {
					await this.fetchMasterSearch(passedData);

					if (this.getAllMasterSearch !== "undefined" &&
						this.getAllMasterSearch !== null && 
						this.getAllMasterSearch !== undefined) {
						if(this.searchData !== ''){
                        	this.isShowSearchResults = true
                        }
						this.searchResults = this.getAllMasterSearch;

						// console.log("OYE: ", this.searchResults);
						if (
							this.searchResults.documents.length == 0 &&
							this.searchResults.products.length == 0 &&
							this.searchResults.purchase_order.length == 0 &&
							this.searchResults.shipments.length == 0 &&
							this.searchResults.suppliers.length == 0
						) {
							this.isShowNoMatchView = true;
							this.isShowSearchResults = false;
							if(this.searchData === ''){
                                this.isShowSearchView = true
                            }else{
                                this.isShowSearchView = false
                            }
						} else {
							this.isShowNoMatchView = false;
							this.isShowSearchResults = true;
							if(this.searchData === ''){
                                this.isShowSearchView = true
                            }else{
                                this.isShowSearchView = false
                            }
						}
					}
				} catch (e) {
					throw Error(e);
				}
			} else {
				this.clear();
			}
		},
		async goToShipmentDetails(data) {
			try {
				await this.fetchNewShipmentDetails(data.id);
				this.clear();
			} catch (e) {
				console.log(e);
			}
		},
		date(date) {
			return moment(date).format("MM/DD/YYYY");
		},
		async loadMoreProducts() {
			if (this.productsCurrentPage < this.productsSearchResults.last_page) {
				this.productsCurrentPage++;

				try {
					let passedData = {
						cancelToken: new CancelToken(function executor(c) {
							cancel = c;
						}),
						params: {
							search: this.searchData,
							page: this.productsCurrentPage,
						},
					};

					let loadProducts = await this.$store.dispatch(
						"products/fetchProductsSearchGlobal",
						passedData
					);

					if (typeof loadProducts !== "undefined" &&
						loadProducts !== null &&
						typeof loadProducts.data !== "undefined" &&
						Array.isArray(loadProducts.data) &&
						loadProducts.data.length > 0) {
						let newloaddata = loadProducts.data;

						this.productsData = [
							...this.productsData,
							...newloaddata,
						];

						if (
							this.productsCurrentPage <
							this.productsSearchResults.last_page
						) {
							this.loadMoreProducts();
						}
					} else {
						this.productsData;
					}
				} catch (e) {
					// this.notificationError(e)
					console.log(e);
				}
			}
		},
		openProductViewDialog(data) {
			let products = { ...data };

			if (!products.carton_dimensions) {
				products = {
					...products,
					carton_dimensions: {
						l: 0,
						w: 0,
						h: 0,
						uom: "cm",
					},
				};
			} else {
				let parseDimensions = JSON.parse(products.carton_dimensions);
				products = { ...products, carton_dimensions: parseDimensions };
			}

			if (!products.unit_dimensions) {
				products = {
					...products,
					unit_dimensions: {
						l: 0,
						w: 0,
						h: 0,
						uom: "cm",
					},
				};
			} else {
				let parseDimensions = JSON.parse(products.unit_dimensions);
				products = { ...products, unit_dimensions: parseDimensions };
			}

			if (!products.unit_weight) {
				products = {
					...products,
					unit_weight: {
						l: 0,
						w: 0,
						h: 0,
						uom: "cm",
					},
				};
			} else {
				let parseDimensions = JSON.parse(products.unit_weight);
				products = { ...products, unit_weight: parseDimensions };
			}

			this.editedProductItem = Object.assign({}, products);
			this.dialogViewProduct = true;
			this.isOpen = false;
			this.searchData = "";
		},
		closeProductViewDialog() {
			this.$nextTick(() => {
				this.editedProductItem = Object.assign(
					{},
					this.defaultProductItem
				);
				this.editedIndexProduct = 0;
			});

			this.dialogViewProduct = false;
		},
		editProduct(product) {
			this.editedIndexProduct = this.productsData.indexOf(product);

			if (this.editedIndexProduct == -1) {
				if (typeof product.id !== "undefined") {
					this.editedIndexProduct = _.findIndex(
						this.productsData,
						(e) => e.id == product.id
					);
				}
			}

			let tempProduct = { ...product };
			if (!tempProduct.carton_dimensions) {
				tempProduct = {
					...tempProduct,
					carton_dimensions: {
						l: 0,
						w: 0,
						h: 0,
						uom: "cm",
					},
				};
			}

			if (!tempProduct.unit_dimensions) {
				tempProduct = {
					...tempProduct,
					unit_dimensions: {
						l: 0,
						w: 0,
						h: 0,
						uom: "cm",
					},
				};
			}

			if (!tempProduct.unit_weight) {
				tempProduct = {
					...tempProduct,
					unit_weight: {
						value: 0,
						unit: "kg",
					},
				};
			}

			if (!tempProduct.is_for_classification_code) {
				tempProduct = {
					...tempProduct,
					is_for_classification_code: 0,
					userClassification: 1,
				};
			}

			if (
				!tempProduct.classification_code ||
				tempProduct.classification_code === "null"
			) {
				tempProduct = { ...tempProduct, classification_code: "" };
			}

			if (
				!tempProduct.additional_classification_code ||
				tempProduct.additional_classification_code === "null"
			) {
				tempProduct = {
					...tempProduct,
					additional_classification_code: "",
				};
			}

			this.editedProductItem = { ...tempProduct };
			this.dialogAddProduct = true;
		},
		closeProduct() {
			this.$nextTick(() => {
				this.editedProductItem = Object.assign(
					{},
					this.defaultProductItem
				);
				this.editedIndexProduct = 0;
			});
			this.dialogAddProduct = false;
		},
		deleteProductItem(item) {
			this.dialogDeleteProduct = true;
			this.currentProductToDelete = item;
		},
		async deleteProductConfirm() {
			if (this.currentProductToDelete !== null) {
				try {
					let storePagination = this.$store.state.products.products;
					let page =
						typeof storePagination.current_page !== "undefined"
							? storePagination.current_page
							: 1;

					await this.deleteProduct(this.currentProductToDelete.id);

					if (storePagination.products.length === 1 &&
						storePagination.current_page !== 1) {
						page = page - 1;
					}

					this.notificationCustom("Product has been deleted.");
					this.closeDeleteProduct();

					await this.fetchProducts(page);
				} catch (e) {
					this.closeDeleteProduct();
					this.notificationError(e);
				}
			}
		},
		closeDeleteProduct() {
			this.dialogDeleteProduct = false;
		},
		setToDefault() {
			this.editedProductItem = {
				sku: null,
				name: "",
				category_id: null,
				description: "",
				units_per_carton: "",
				image: null,
				classification_code: "",
				additional_classification_code: "",
				duty_rate: "",
				unit_price: 0,
				upc_number: "",
				carton_upc: "",
				is_for_classification_code: 1,
				userClassification: 0,
				country_from: "",
				country_to: "",
				product_classification_description: "",
				carton_dimensions: {
					l: 0,
					w: 0,
					h: 0,
					uom: "cm",
				},
				unit_dimensions: {
					l: 0,
					w: 0,
					h: 0,
					uom: "cm",
				},
				unit_weight: {
					value: 0,
					unit: "kg",
				},
			};
		},
		async companyDetail() {
			let customerDetail;
			let getUser;
			if (typeof this.getUser === "string") {
				getUser = JSON.parse(this.getUser);
			} else {
				getUser = this.getUser;
			}
			customerDetail = getUser.default_customer;
			this.SET_CUSTOMER(customerDetail);
			this.SET_GENRAL_LOADER(false);

			if (this.getOldCustomerId !== getUser.default_customer_id) {
				let payload = { id: getUser.default_customer_id, page: 1 };
				await this.getNotificationFromApi(payload);
				await this.getArchivedMessages(payload);	
				this.SET_OLD_CUSTOMER_ID(getUser.default_customer_id);
			}
			
			// let getCustomers = getUser?.customers_api;
			// if (getCustomers && getCustomers.length > 0) {
			// 	// let selectedCustomer = getUser.default_customer_id;
			// 	// customerDetail = _.find(getCustomers, function(customer) {
			// 	// 	if (customer.id === selectedCustomer) {
			// 	// 		return true;
			// 	// 	}
			// 	// });
			// 	customerDetail = getUser.default_customer;
			// 	this.SET_CUSTOMER(customerDetail);
			// 	this.SET_GENRAL_LOADER(false);
			// 	// this.getNotification();
			// }
		},
		// getNotification() {
		// 	this.getNotificationFromApi();
		// 	this.getArchivedMessages();
		// 	// this.connectToNotificationChannel();
		// },

		showSearchView() {
			console.log("ok")
			this.isOpen = true;
			if(this.searchData == ''){
                this.isShowSearchView = true
            }
		},
		hideSearchView() {
			//this.isOpen = false;
			// this.isShowSearchView = false;
			this.isShowSearchResults = false;
			if(this.searchData == ''){
                this.isShowNoMatchView = false
            }
		},
		setActiveTrue() {
			this.isInputExpandedNewActive = true;
			setTimeout(() => {
				this.isInputExpandedNewActive = false;
			}, 100);
		},
		onAutocompleteChange() {
            if(this.searchData != ''){
            this.apiCall(this.searchData)
            }
        },
		includeNew() {
			return [document.getElementsByClassName("included")];
		},
		onClickOutside() {
			if (this.isInputExpandedNewActive) {

				return;
			}
			if(this.searchData == ''){

				this.isInputExpandedNew = false;
				this.isOpen = false;
				this.searchData = "";
				this.selectedSearchType = "All";
				this.searchResults = {
					customers: null,
					documents: null,
					products: null,
					purchase_order: null,
					shipments: null,
					suppliers: null,
				};
				this.isShowSearchResults = false;
			}
			if(this.searchData !== ''){
				this.isInputExpandedNew = true;
				this.isShowSearchView = false;
				this.isShowNoMatchView = false;
                this.isShowSearchResults = false
				this.searchResults = {
					customers: null,
					shipments: null,
					suppliers: null,
					products: null,
					purchase_order: null,
					documents: null
                }
			}
			this.$refs.refInput.blur()

		},		
		onClickDrawer() {
			this.drawer = !this.drawer;
			// this.checkWrapperOverflow();
		},
		checkWrapperOverflow() { // this is for scrollbar adding of padding - needs to be worked on
			setTimeout(() => {
				let div = document.querySelector('.v-navigation-drawer__content');
				if (div !== undefined && div !== null) {
					let hasScrollbar = !!(div.scrollTop || (++div.scrollTop && div.scrollTop--));
					if (hasScrollbar)
						jQuery('.shifl-navigation-drawer').addClass('has-scrollbar');						
					else
						jQuery('.shifl-navigation-drawer').removeClass('has-scrollbar'); 
				}
			}, 100);
        },
	},
	computed: {
		...mapGetters("notificationNew", ["getUnreadMessages", "getCustomer", "getOldCustomerId"]),
		...mapGetters({
			getUserToken: "getUserToken",
			getUser: "getUser",
			getAllMasterSearch: "getAllMasterSearch",
			masterSearchLoading: "masterSearchLoading",
			getDeleteProductLoading: "products/getDeleteProductLoading",
			getAllCategoriesDropdown: "category/getAllCategoriesDropdown",
			getLoadingUserDetails: "getLoadingUserDetails",
			getAddOverlay: "getAddOverlay",
		}),
		getBetaUrl() {
			let betaUrl = this.$store.getters["page/getBetaUrl"];
			betaUrl = betaUrl.replace("api", "");
			return betaUrl;
		},
		hideSidebarAndHeader() {
			if (this.currentRouteName) {
				return this.hideSidebarAndHeaderPages.includes(
					this.currentRouteName
				);
			} else {
				return true;
			}
		},
		showSpecialDialog() {
			return this.$store.getters.getSpecialDialog;
		},
		currentRouteName() {
			return this.$route.name;
		},
		poGlobalSearchLoading() {
			return this.$store.getters["po/getPoGlobalSearchLoading"];
		},
		isPoResultShow() {
			let isShow = false;

			if (this.currentRouteName === "POs" ||
				this.currentRouteName === "PO Details") {
				isShow = true;
			}

			return isShow;
		},
		isShipmentResultShow() {
			let isShow = false;

			if (this.currentRouteName !== "POs" ||
				this.currentRouteName !== "PO Details" ||
				this.currentRouteName !== "Products") {
				isShow = true;
			}

			return isShow;
		},
		isProductResultShow() {
			let isShow = false;

			if (this.currentRouteName === "Products") {
				isShow = true;
			}

			return isShow;
		},
		isShowEmptyResults() {
			let isShow = false;

			if (!this.masterSearchLoading) {
				if ((this.currentRouteName === "POs" || this.currentRouteName === "PO Details") &&
					this.poResults.length === 0) {
					isShow = true;
				} else if (this.currentRouteName === "Products" && this.productsData.length === 0) {
					isShow = true;
				} else if (
					this.currentRouteName !== "POs" &&
					this.currentRouteName !== "PO Details" &&
					this.currentRouteName !== "Products" &&
					this.searchResults.customers === null &&
					this.searchResults.shipments === null
				) {
					isShow = true;
				}
			}
			return isShow;
		},
	},
	mounted() {
		this._processMessaging();
		this.companyDetail();

		document.addEventListener(
			"visibilitychange",
			this._handleVisibilityChange,
			false
		);
		document.title = "Shifl Appdev";
		const currentUrl = window.location.href;
		this.isShowDrawer = currentUrl.includes("api-documentation");
	},
	updated() {
		if (window.innerWidth !== this.currentWidth) {
			this.currentWidth = window.innerWidth;
		}

		if (this.currentRouteName === "Products") {
			if (typeof this.getAllCategoriesDropdown !== "undefined") {
				this.categoryListData = this.getAllCategoriesDropdown;
			}
		}
	},
};
</script>

<style lang="scss">
.notifi-count {
	position: absolute;
	top: -5px;
	right: -5px;
	background-color: $shifl-error-r80;
	width: 17px;
	height: 17px;
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.messages-count {
	font-size: 12px;
	color: #fff;
	margin: -2px !important;
	font-family: "Inter-Medium";
}

.mess-count-la {
	font-size: 10px;
}

.large-div {
	height: 20px;
	width: 20px;
	top: -7px;
	right: -7px;
}

.searchHelpMobile {
	.v-card{
		display: flex;
	}
	position: relative;
	border-radius: 4px;

	&.expanded-top {
		background-color: #f5f9fc;
		padding: 0;
		margin: 0;
	}
}

.searchHelpMobile .search-icon {
	width: 18px;
	position: absolute;
	top: 19px;
	left: 12px;
	z-index: 10;
}

.searchHelpMobile .global-search-text-autocomplete {
	border: none;
	outline: none;
	border-radius: 4px !important;
}

.searchHelpMobile .global-search-text-autocomplete:focus {
	border: none;
	outline: none;
	border-radius: 4px !important;
	background-color: #f9fafb;
}
.searchHelpMobile .dropdown-mobile-search.v-autocomplete.v-select.v-input--is-focused input {
    display: none;
}
.searchHelpMobile {
	.global-search-text-autocomplete {
		color: #253041 !important;
		padding-left: 5px;
		font-weight: 500;
		font-size: 14px;
		width: 30px;
		min-width: 30px;
		// padding: 6px 20px 6px 12px;
		height: 45px;
		background-color: transparent !important;
		// -webkit-transition: all 0.5s ease;
		// -moz-transition: all 0.5s ease;
		// transition: all 0.5s ease;

		&.expanded {
			min-width: calc(80vw - 75px);
			background-color: white;
			outline: 0;
			border-radius: 2px;
			font-size: 14px !important;

			&::placeholder {
				color: $shifl-neutral-n40 !important;
			}
		}
		&.expendedwidth{
			min-width: calc(80vw - 30px) !important;
			background-color: white;
			outline: 0;
			border-radius: 2px;
			font-size: 14px !important;
			// transition: min-width 0 linear 1s;

			&::placeholder {
				color: $shifl-neutral-n40 !important;
			}
		}
		&.expendedwidthMBL {
			min-width: calc(80vw - 39px) !important;
			background-color: white;
			outline: 0;
			border-radius: 2px;
			font-size: 14px !important;
			// transition: min-width 0 linear 1s;

			&::placeholder {
				color: $shifl-neutral-n40 !important;
			}
		}
	}

	.dropdown-mobile-search.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
		> .v-input__control
		> .v-input__slot,
	.v-text-field.v-text-field--enclosed .v-text-field__details {
		padding: 0 2px;
		background-color: #f3f4f7;
		border: 1px solid transparent;
		border-radius: 0;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		height: 45px;
		min-width: 35px;
		width: 109px;
		max-width: 102px !important;
		
	}
	.dropdown-mobile-search-forAll.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
		> .v-input__control
		> .v-input__slot,
	.v-text-field.v-text-field--enclosed .v-text-field__details {
		min-width: 55px;
		width: fit-content;
		max-width: 55px !important;
	}
	.dropdown-mobile-search-forAll.v-autocomplete.v-select.v-input--is-focused input {
		min-width: 25px !important;
		padding-left: 2px;
		outline: 0;
		border-radius: 0;
		height: 45px;
	}
	.dropdown-mobile-search-forMBL.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
		> .v-input__control
		> .v-input__slot,
	.v-text-field.v-text-field--enclosed .v-text-field__details {
		min-width: 63px;
		width: fit-content;
		max-width: 63px !important;
	}
	.dropdown-mobile-search-forMBL.v-autocomplete.v-select.v-input--is-focused input {
		min-width: 28px !important;
		padding-left: 2px;
		outline: 0;
		border-radius: 0;
		height: 45px;
	}

	.dropdown-mobile-search.v-autocomplete.v-select.v-input--is-focused input {
		min-width: 40px;
		padding-left: 2px;
		outline: 0;
		border-radius: 0;
		height: 45px;
	}

	.dropdown-mobile-search.v-text-field
		> .v-input__control
		> .v-input__slot:after {
		background-color: currentColor;
		border-color: currentColor;
		border-style: none;
		border-width: thin 0 thin 0;
	}

	.dropdown-mobile-search .v-input__control .v-input__slot::before {
		border: none !important;
	}
	.dropdown-mobile-search .v-text-field.v-input--dense .v-input__prepend-inner, .v-text-field.v-input--dense .v-input__append-inner {
    	margin-top: 0px;
    	padding-left: 0 !important;
	}
	.v-select--is-menu-active{
	background-color: white !important;
	}
	
}

.global-search-text-autocomplete::placeholder {
	color: #b4cfe0 !important;
}

.global-search-text-autocomplete.open {
	background-color: #f9fafb;
}
.dropdown-mobile-search.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active.v-autocomplete__content {
	width: 168px !important;
}

.loading-search {
	display: flex;
	justify-content: center;
	align-items: center;
	// padding-top: 20px;
}

.global-search-for-bg-active-mobile::before{
    opacity: 0;
    color: #4A4A4A !important;
    background-color: rgba(245, 249, 252, 0);
	width:168px;
}
.global-search-for-bg-active-mobile:hover::before{
    background-color: rgb(203, 200, 200) !important;
    opacity: 0.5;
    color: #4A4A4A !important;
}

@import "./assets/scss/app.scss";
</style>
<style scoped>
/* @media (min-width: 10px) {
	.v-navigation-drawer {
		width: 100% !important;
	}
}

@media (min-width: 415px) {
	.v-navigation-drawer {
		width: 216px !important;
	}
} */

.no-match-data {
	height: 200px;
	opacity: 1;
	/* transform: translateY(0); */
	padding: 20px !important;
	text-align: center;
}
.highlight {
	background-color: #FEF7B8;
	font-family: "Inter-SemiBold", sans-serif;
}
.add-overlay-to-overall-page {
	position: absolute;
	top: 0;
	width: 100%;
	/* height: 91vh; */
	height: 100%;
	background-color: #4a4a4a !important;
	opacity: 0.5 !important;
	z-index: 4 !important;
}
</style>
