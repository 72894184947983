/** @format */
import axios from "axios";
const betaBaseUrl = process.env.VUE_APP_BASE_URL;

const state = {
	generateApiTokenLoading: false,
	fetchApiTokenLoading: false,
	revokeApiTokenLoading: false,
	apiTokenData: [],
};

const getters = {
	getGenerateApiTokenLoading: (state) => state.generateApiTokenLoading,
	getFetchApiTokenLoading: (state) => state.fetchApiTokenLoading,
	getRevokeApiTokenLoading: (state) => state.revokeApiTokenLoading,
	getApiTokenData: (state) => state.apiTokenData,
};

const actions = {
	createAPIToken: async ({ commit }, payload) => {
		commit("SET_GENERATE_API_TOKEN_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.post(`${betaBaseUrl}/generate-api-token`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_GENERATE_API_TOKEN_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_GENERATE_API_TOKEN_LOADING", false);
					reject(err);
				});
		});
	},
	fetchAPIToken: async ({ commit }) => {
		commit("SET_FETCH_API_TOKEN_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.get(`${betaBaseUrl}/get-api-tokens`)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_FETCH_API_TOKEN_LOADING", false);
							commit("SET_FETCH_API_TOKEN_VALUE", res.data.data);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_FETCH_API_TOKEN_LOADING", false);
					reject(err);
				});
		});
	},
	revokeAPIToken: async ({ commit }, payload) => {
		console.log("payload", payload);

		commit("SET_REVOKE_API_TOKEN_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.put(`${betaBaseUrl}/revoke-api-token`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_REVOKE_API_TOKEN_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_REVOKE_API_TOKEN_LOADING", false);
					reject(err);
				});
		});
	},
};

const mutations = {
	SET_GENERATE_API_TOKEN_LOADING: (state, payload) => {
		state.generateApiTokenLoading = payload;
	},
	SET_FETCH_API_TOKEN_LOADING: (state, payload) => {
		state.fetchApiTokenLoading = payload;
	},
	SET_REVOKE_API_TOKEN_LOADING: (state, payload) => {
		state.revokeApiTokenLoading = payload;
	},
	SET_FETCH_API_TOKEN_VALUE: (state, payload) => {
		state.apiTokenData = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
