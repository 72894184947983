<template>
    <div class="pos d-flex align-items-center justify-start">
        <img src="@/assets/images/logo.svg" alt="" width="118px" class="toggled-logo-icon mr-2" />
        <div class="searchHelp" id="dropdown" v-bind:style="{ border: inputBorderStyle, backgroundColor: inputBackgroundColor }" v-if="!isMobile">
            <img src="@/assets/images/search-icon.svg" alt="" class="search-icon" />
            <v-select 
                v-if="isOpen || showOpen" 
                item-color="#4A4A4A" 
                :menu-props="{ bottom: true, offsetY: true}"  
                class="global-search-text-fields"
                :class="addStyle ? 'global-search-text-fieldsnew' : ''"
                @blur="addStyle=false"
                @click="onAutocompleteClick" 
                color="blue" 
                @change="onAutocompleteChange" 
                style="margin-top: 10px !important;" 
                outlined
                :items="['All', 'Reference', 'MBL', 'Custom Reference', 'Container', 'PO', 'Shipper']" v-model="selectedSearchType"
                item-text="name" 
                item-value="id" 
                append-icon="mdi-chevron-down" 
                dense 
                hide-details="auto" 
                autocomplete="off"
            >   
                <template v-slot:selection="{ item }">
                    <span style="color: #253041;font-size: 12px;font-weight: 400;margin-top: 1px;margin-right: 5px;">{{ item === 'Custom Reference' ? 'Cust. Ref#' :item }}</span>
                </template>
                <template v-slot:item="{ item, on, attrs }">
                    <v-list-item
                        active-class="global-search-for-bg-active"
                        v-on="on"
                        v-bind="attrs"
                        @click.stop="()=> {}">
                        <v-list-item-content>
                            <v-list-item-title>
                                <p class="name mb-1 font-medium">
                                    {{ item }}
                                </p>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-select>

            <input 
                :class="showOpen ? 'open' : 'close'" 
                class="global-search-text-autocomplete" 
                type="text" 
                @input="handleSearch"
                :style="searchData !== '' && !isOpen ? 'background-color: white;':''"
                @click="showSearchView" 
                id="input-search-data" 
                v-model.trim="searchData" 
                placeholder="Search anything..."
                autocomplete="off" 
            />
            <br />

            <button 
                v-if="searchData !== ''" 
                class="close-btn" 
                @click="clearInput"
            >
                <img 
                    src="../assets/images/close.svg" 
                    alt="" 
                    width="18px"
                />
            </button>
            <div class="search-results" :class="isOpen ? 'open-dropdown' : 'close-dropdown'">
                <ul class="loading-search-wrapper" v-if="masterSearchLoading">
                    <div class="loading-search">
                        <!-- <v-progress-circular :size="30" color="#1A6D9E" indeterminate>
                        </v-progress-circular> -->
                        <v-row no-gutters>
                            <v-col class="pa-0 ma-0" cols="12" v-for="n in 4" :key="n">
                                <v-skeleton-loader
                                    class="px-4"
                                    height="90"
                                    type="table-cell,list-item-avatar-two-line">
                                </v-skeleton-loader>
                                <v-divider style="border-color: #EBF2F5 !important;"></v-divider>
                            </v-col>
                        </v-row>
                    </div>
                </ul>
                <!-- FOR PO Search Results -->
                <!-- <ul class="has-data" v-if="isPoResultShow && !masterSearchLoading && poResults.length > 0">
                    <div v-if="typeof poResults !== 'undefined' && poResults.length > 0">
                        <p class="dropdown-title"> Purchase Orders </p>
                        <div v-for="(data, index) in poResults" :key="index">

                            <li class="dropdown-data">
                                <router-link :to="`/po-details/${data.id}`">
                                    <img src="../assets/images/search-shipment-icon.svg" alt="">
                                    <div>
                                        <p class="data-ref">{{ "PO # " + data.po_number }}</p>
                                        <p class="data-location">
                                            {{ (typeof data.status !== 'undefined' && data.status !== '' && data.status !== null)
                                                ? ucWords(data.status) : 'No status' }}
                                        </p>
                                    </div>
                                </router-link>
                            </li>
                        </div>
                    </div>
                </ul> -->

                <!-- FOR Products Search Results -->
                <!-- <ul class="has-data" v-if="isProductResultShow && !masterSearchLoading && searchResults.products.length > 0">

                    <div v-if="typeof searchResults.products !== 'undefined' && searchResults.products.length > 0">
                        <p class="dropdown-title"> Products </p>

                        <div v-for="(data, index) in  searchResults.products" :key="index">
                            <li class="dropdown-data" v-on:click="openProductViewDialog(data)">
                                <router-link :to="`#`">
                                    <img src="../assets/images/empty-product-icon.svg" alt="" width="35px" height="35px"
                                        class="product-img">
                                    <div>
                                        <p class="data-ref">{{ "SKU #" + data.category_name }}</p>
                                        <p class="data-location"> {{ data.name }}</p>
                                    </div>
                                </router-link>
                            </li>
                        </div>
                    </div>
                </ul> -->

                <!-- FOR Shipments Search Results -->
                <!-- <ul class="has-data" v-if="isShipmentResultShow && !masterSearchLoading && searchResults.shipments !== null">
                    <div v-if="isShipmentResultShow && searchResults.shipments !== 'undefined' && searchResults.shipments !== null">
                        <p class="dropdown-title"> Shipments </p>

                        <div v-for="(data, index) in searchResults.shipments" :key="index">

                            <li class="dropdown-data" v-on:click="goToShipmentDetails(data)">
                                <router-link :to="`/shipment/${data.id}`">
                                    <img src="../assets/images/search-shipment-icon.svg" alt="">
                                                            <div>
                                        <p class="data-ref">{{ data.shifl_ref }}</p>
                                         <p class="data-location">
                                            {{ data.location_from_name!== 'undefined' 
                                                || data.location_from_name!== null 
                                                || typeof data.location_from_name== 'undefined'
                                                    ? data.location_from_name.toLowerCase() !=='not specified' ? `${data.location_from_name} (${convertUTCDatetoLocal(data.etd)}) - ` : data.search_contain_tracking ? `${data.search_tracking_from}(${data.search_tracking_etd}) - ` : 'Not Specified - ' : 'Not Specified'
                                            }}

                                             {{ data.location_to_name !== 'undefined' && data.location_to_name !== null
                                                ? data.location_to_name.toLowerCase() !=='not specified' ? `${data.location_to_name} (${convertUTCDatetoLocal(data.eta)})` : data.search_contain_tracking ? `${data.search_tracking_to} (${data.eta})`: 'Not Specified'
                                                : 'Not Specified'
                                            }}
                                        </p>
                                    </div>

                                </router-link>
                            </li>
                        </div>
                    </div> 
                </ul> -->


                <ul class="has-data" v-if="isShowSearchResults && !masterSearchLoading && searchResults !== null && searchData !== ''">
                   
                    <div v-if="typeof searchResults.suppliers !== 'undefined' && searchResults.suppliers !== null && searchResults.suppliers.length > 0 && searchData !== ''">
                        <p class="dropdown-title"> Suppliers </p>

                        <div v-for="(data, index) in  searchResults.suppliers" :key="index">
                            <li class="dropdown-data" >
                                <router-link :to="`/contact?supName=${data.display_name}`">
                                    <img src="../assets/images/search-supplier-icon.svg" alt="" width="35px" height="35px"
                                        >
                                    <div>
                                        <p class="data-ref">{{ (data.display_name == '' ||  data.display_name == undefined) ? data.company_name : data.display_name}}</p>
                                        <p class="data-location"> {{ data.phone }}</p>
                                    </div>
                                </router-link>
                            </li>
                        </div>
                    </div>

                    <div v-if="typeof searchResults.shipments !== 'undefined' && searchResults.shipments !== null && searchResults.shipments.length > 0 && searchData !== ''">
                        <p class="dropdown-title"> Shipments </p>
                        <div v-for="(data, index) in searchResults.shipments" :key="index">
                            <li class="dropdown-data" v-on:click="goToShipmentDetails(data)">
                                <router-link :to="`/shipment/${data.id}`">
                                    <img src="../assets/images/search-shipment-icon.svg" alt="">
                                                            <div>
                                        <p class="data-ref">
                                            <span  class="data-ref" v-html="highlightText(data.shifl_ref)"></span>

                                            </p>
                                         <p class="data-location">
                                            {{ data.location_from_name!== 'undefined' 
                                                || data.location_from_name!== null 
                                                || typeof data.location_from_name== 'undefined'
                                                    ? data.location_from_name.toLowerCase() !=='not specified' ? `${data.location_from_name} (${convertUTCDatetoLocal(data.etd)}) - ` : data.search_contain_tracking ? `${data.search_tracking_from}(${data.search_tracking_etd}) - ` : 'Not Specified - ' : 'Not Specified'
                                            }}

                                             {{ data.location_to_name !== 'undefined' && data.location_to_name !== null
                                                ? data.location_to_name.toLowerCase() !=='not specified' ? `${data.location_to_name} (${convertUTCDatetoLocal(data.eta)})` : data.search_contain_tracking ? `${data.search_tracking_to} (${data.eta})`: 'Not Specified'
                                                : 'Not Specified'
                                            }}
                                        </p>
                                    </div>

                                </router-link>
                            </li>
                        </div>
                    </div>

                    <div v-if="typeof searchResults.purchase_order !== 'undefined' && searchResults.purchase_order !== null && searchResults.purchase_order.length > 0 && searchData !== ''">
                        <p class="dropdown-title"> Purchase Orders </p>
                        <div v-for="(data, index) in searchResults.purchase_order" :key="index">

                            <li class="dropdown-data">
                                <router-link :to="`/po-details/${data.id}`">
                                    <img src="../assets/images/search-po-orders-icon.svg" alt="">
                                    <div>
                                        <p class="data-ref">{{ "PO # " }} <span class="data-ref" v-html="highlightText(data.po_number)"></span></p>
                                        <p class="data-location">
                                            {{  `${convertUTCDatetoLocal(data.cargo_ready_date)} *  ${data.supplier_name}`}}

                                        </p>
                                    </div>
                                </router-link>
                            </li>
                        </div>
                    </div>

                    <div v-if="typeof searchResults.products !== 'undefined' && searchResults.products !== null && searchResults.products.length > 0 && searchData !== ''">
                        <p class="dropdown-title"> Items </p>

                        <div v-for="(data, index) in  searchResults.products" :key="index">
                            <li class="dropdown-data" v-on:click="openProductViewDialog(data)">
                                <router-link :to="`#`">
                                    <img src="../assets/images/empty-product-icon.svg" alt="" width="35px" height="35px"
                                        class="product-img">
                                    <div>
                                        <p class="data-ref">{{ "SKU #" + data.category_name }}</p>
                                        <p class="data-location"> {{ data.name }}</p>
                                    </div>
                                </router-link>
                            </li>
                        </div>
                    </div>
                    <div v-if="typeof searchResults.documents !== 'undefined' && searchResults.documents !== null && searchResults.documents.length > 0 && searchData !== ''">
                        <p class="dropdown-title"> Documents </p>

                        <div v-for="(data, index) in  searchResults.documents" :key="index">
                            <li class="dropdown-data">
                                <button @click="previewPdfFile(data.path)">
                                    <img src="../assets/images/search-document-icon.svg" alt="" width="35px" height="35px"
                                        >
                                    <div>
                                        <p class="data-ref">{{  data.type }}</p>
                                        <p class="data-location">  {{  `${convertUTCDatetoLocal(data.updated_at)} *  ${data.name}`}}</p>
                                    </div>
                                </button>
                            </li>
                        </div>
                    </div>

                </ul>
                <!-- FOR Click on Search Results -->

                <ul class="no-match-data shipments" :class="masterSearchLoading ? 'extend-height':''" v-if="isShowNoMatchView && !masterSearchLoading && !isShowSearchView && searchData !== ''">
                    <div style="width: 100%;display: flex;flex-direction: column;align-items: center;">
                        <span class="no-match-img">
                            <img src="../assets/icons/no-match-data.svg" alt="" srcset="">
                        </span>
                        <h4>No Match Found</h4>
                        <p style="font-size: 14px;">We couldn’t find any result that matches your search<br> term. Can you please check the spelling or try a<br> different search?
                        </p>
                    </div>
                </ul>

                <ul  class="search-any-data shipments" v-if="isShowSearchView && searchData == ''">
                    <div style="width: 100%;display: flex;flex-direction: column;align-items: center;" >
                        <span class="search-any-data-img">
                            <img src="../assets/images/search-data-icon.svg" alt="" srcset="">
                        </span>
                        <h4>Search Anything</h4>
                        <p style="font-size: 14px;">Start typing your search term (e.g. MBL number,<br> shipment reference) or, Select a category to narrow<br> down your results.
                        </p>
                    </div>
                </ul>
            </div>
        
        <div class="mobile-search-wrapper" v-if="isMobile">
            <img src="@/assets/images/search-icon.svg" alt="" class="search-icon" />
        </div>
        <ProductViewDialog :editedItemData.sync="editedProductItem" :dialogViewData.sync="dialogViewProduct"
            :isMobile="isMobile" :categoryLists="categoryListData" @editItem="editProduct" @deleteItem="deleteProductItem"
            @close="closeProductViewDialog" />

        <ProductAddDialog :dialog.sync="dialogAddProduct" :editedIndex.sync="editedIndexProduct"
            :defaultItem.sync="defaultProductItem" :editedItem.sync="editedProductItem" :categoryLists="categoryListData"
            @close="closeProduct" @setToDefault="setToDefault" :isMobile="isMobile" :isInventoryPage="false"
            :isWarehouse3PL="false" :isWarehouse3PLProvider="false" />

        <DeleteDialog :dialogData.sync="dialogDeleteProduct" :editedItemData.sync="currentProductToDelete"
            :editedIndexWarehouse.sync="editedIndexProduct" :defaultItemWarehouse.sync="defaultProductItem"
            @delete="deleteProductConfirm" @close="closeDeleteProduct" fromComponent="products"
            :loadingDelete="getDeleteProductLoading" componentName="Products" />
    </div>
</div>
</template>

<script>
import axios from "axios"
import _ from 'lodash'
import moment from 'moment'
import { mapActions, mapGetters,mapMutations } from "vuex";
import globalMethods from '../utils/globalMethods'
import ProductViewDialog from '../components/ProductComponents/Dialog/ProductViewDialog.vue'
import ProductAddDialog from '../components/ProductComponents/Dialog/ProductAddDialog.vue'
import DeleteDialog from '../components/Dialog/DeleteDialog.vue'

var cancel;
var CancelToken = axios.CancelToken;

export default {
    props: ["isMobile"],
    components: {
        ProductViewDialog,
        ProductAddDialog,
        DeleteDialog
    },
    data: () => ({
        showOpen:false,
        inputBorderStyle: "",
        inputBackgroundColor: "",

        selectedSearchType: "All",
        isShowSearchView: false,
        isShowNoMatchView: false,
        isShowSearchResults: false,
        loading: false,
        poResults: [],
        searchResults: {
            customers: null,
            shipments: null,
            suppliers: null,
            products: null,
            purchase_order: null,
            documents: null
        },
        noResults: false,
        searching: false,
        searchData: '',
        isOpen: false,
        productsSearchResults: [],
        productsData: [],
        productsCurrentPage: 1,
        dialogViewProduct: false,
        dialogAddProduct: false,
        dialogDeleteProduct: false,
        editedProductItem: {
            sku: null,
            name: '',
            category_id: null,
            description: '',
            units_per_carton: '',
            image: null,
            classification_code: '',
            additional_classification_code: '',
            duty_rate: '',
            unit_price: 0,
            upc_number: '',
            carton_upc: '',
            is_for_classification_code: 1,
            userClassification: 0,
            country_from: '',
            country_to: '',
            product_classification_description: '',
            carton_dimensions: {
                l: 0,
                w: 0,
                h: 0,
                uom: 'cm'
            },
            unit_dimensions: {
                l: 0,
                w: 0,
                h: 0,
                uom: 'cm'
            },
            unit_weight: {
                value: 0,
                unit: 'kg'
            }
        },
        defaultProductItem: {
            sku: null,
            name: '',
            category_id: null,
            description: '',
            units_per_carton: '',
            image: null,
            classification_code: '',
            additional_classification_code: '',
            duty_rate: 0,
            unit_price: 0,
            upc_number: '',
            carton_upc: '',
            is_for_classification_code: 1,
            userClassification: 0,
            country_from: '',
            country_to: '',
            product_classification_description: '',
            carton_dimensions: {
                l: 0,
                w: 0,
                h: 0,
                uom: 'cm'
            },
            unit_dimensions: {
                l: 0,
                w: 0,
                h: 0,
                uom: 'cm'
            },
            unit_weight: {
                value: 0,
                unit: 'kg'
            }
        },
        currentProductToDelete: null,
        categoryListData: [],
        editedIndexProduct: 0,
        addStyle:false
    }),
    watch: {
        isOpen(dropOpen) {
            if (dropOpen) {
                document.addEventListener('click', this.closeIfClickedOutside);
            }
        },
    },
    methods: {
        ...mapActions({
            fetchMasterSearch: 'fetchMasterSearch',
            fetchNewShipmentDetails: "shipmentDetailsV2/fetchNewShipmentDetails",
            setMasterSearchLoading: 'setMasterSearchLoading',
            fetchProducts: 'products/fetchProducts',
            deleteProduct: 'products/deleteProduct'
        }),
        ...mapMutations({addOverlayMutate:'addOverlayMutate'}),
        ...globalMethods,
        
        previewPdfFile(pic){
            // let imageUrl = 'https://staging.shifl.com/'
            // let file = ''
            // if (pic !== 'undefined' && pic !== null) {
            //     if (pic.includes(imageUrl) !== 'undefined' && !pic.includes(imageUrl)) {
            //         file = imageUrl + pic
            //         file
            //     } else {
            //         file = pic
            //     }
            // }
            // https://beta.shifl.com/download?link=shipment%2Fdocuments-customer%2Fdf09d7cbc5fd85d9a9ddd1db6e65ec48.PDF
            const downloadUrl = `${this.getBetaUrl}download?link=${encodeURIComponent(pic)}`;
            window.open(downloadUrl, '_blank');

            // var fileURL = window.URL.createObjectURL(pic);
            // window.open(fileURL);
            
        },
        dataURLtoFile(dataurl, filename) {
                var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {type:mime});
            },
        ucWords(str) {
            return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
                return $1.toUpperCase()
            })
        },
        highlightText(item) {

            if(item == null){
                item = "not specified"
            }else{

            if (this.searchData && item.toLowerCase().includes(this.searchData.toLowerCase())) {
                const regex = new RegExp(this.searchData, 'gi');
                return item.replace(regex, match => `<span class="highlight">${match}</span>`);
            }
        }
            return item;
       },
        convertUTCDatetoLocal(date) {
            return date !== null ? moment.utc(date).format('MM/DD/YYYY') : "not specified"

        },
        trackingDateConvert(date) {
            return date !== null ? moment.utc(date).format('MMM DD, YYYY') : null
        },
        showSearchView() {
            this.isOpen = true
            this.showOpen = true
            this.inputBorderStyle = '1px solid #0171A1';
            this.inputBackgroundColor = '#F9FAFB';
            if(this.searchData == ''){
                this.isShowSearchView = true
            }else {
                if(this.searchData !== '' && !this.isShowSearchView && !this.isShowSearchResults){
                    console.log("ssss")
                    this.handleSearch()
                }
            }
            
            // this.isShowNoMatchView = false
            //this.isShowSearchResults = false
            this.addOverlayMutate(true)
        },
        hideSearchView() {
            // this.isOpen = false
            // this.showOpen =false
            // this.isShowSearchView = false
            this.isShowSearchResults = false
            if(this.searchData == ''){
                this.isShowNoMatchView = false
                this.isShowSearchView = true
            }
           

        },
        clearInput() {
            setTimeout(() => {
                this.searching = false
                if(!this.isShowSearchResults && !this.isShowNoMatchView && !this.masterSearchLoading){
                    this.showOpen = false
                    this.inputBorderStyle = '';
                }
                
                this.searchResults = {
                    customers: null,
                    shipments: null,
                    suppliers: null,
                    products: null,
                    purchase_order: null,
                    documents: null
                },
                // this.addOverlayMutate(false)
                // this.inputBorderStyle = '';
                // this.inputBackgroundColor = '';
                this.searchData = ''
                // this.isOpen = false
                // this.showOpen = false
                document.getElementById("input-search-data").focus()
                this.isShowSearchView = true;
            }, 1)
        },
        clear() {
            this.searching = false
            this.searchResults = {
                customers: null,
                shipments: null,
                suppliers: null,
                products: null,
                purchase_order: null,
                documents: null
            },
                this.searchData = ''
            // this.isOpen = false
            this.isShowNoMatchView = false
            this.isShowSearchView = true

        },
        // using lodash
        handleSearch: _.debounce(function () {
            this.hideSearchView()
            this.preApiCall()
        }, 500),
        preApiCall() {
            if (cancel !== undefined) {
                cancel()
                console.log("cancelled");
                this.isShowNoMatchView = false
                this.isShowSearchView = true
                if(this.searchData == ''){
                    this.isShowSearchResults =false
                }
                // this.isOpen = true
            }
            this.apiCall(this.searchData)
        },
        async apiCall(searchData) {
            this.isOpen = true
            this.showOpen =true
            if (searchData !== "undefined" && searchData !== "" && searchData !==undefined) {
                let passedData = {}
                // if (this.currentRouteName === 'POs' || this.currentRouteName == 'PO Details') {
                //     passedData = {
                //         cancelToken: new CancelToken(function executor(c) {
                //             cancel = c
                //         }),
                //         params: {
                //             s: searchData
                //         }
                //     }
                // } else if (this.currentRouteName === 'Products') {
                //     passedData = {
                //         cancelToken: new CancelToken(function executor(c) {
                //             cancel = c
                //         }),
                //         params: {
                //             search: searchData
                //         }
                //     }
                // } else {

                //     passedData = {
                //         method: "GET",
                //         url: "/global-search",
                //         cancelToken: new CancelToken(function executor(c) {
                //             cancel = c
                //         }),
                //         params: {
                //             search_type: this.selectedSearchType,
                //             search_text: searchData
                //         }
                //     }


                // }

                passedData = {
                        method: "GET",
                        url: "/global-search",
                        cancelToken: new CancelToken(function executor(c) {
                            cancel = c
                        }),
                        params: {
                            search_type: this.selectedSearchType.replace(/ /g, "_"),
                            search_text: searchData
                        }
                    }


                try {

                    await this.fetchMasterSearch(passedData)

                    if (this.getAllMasterSearch !== 'undefined' && 
                        this.getAllMasterSearch !== null && this.getAllMasterSearch !== undefined) {
                        if(this.searchData !== ''){
                        this.isShowSearchResults = true
                        }
                        this.searchResults = this.getAllMasterSearch
                   

                        if (  this.getAllMasterSearch.documents.length == 0 &&
                        this.getAllMasterSearch.products.length == 0 &&
                        this.getAllMasterSearch.purchase_order.length == 0 &&
                        this.getAllMasterSearch.shipments.length == 0 &&
                        this.getAllMasterSearch.suppliers.length == 0 
                        ) {
                            this.isShowNoMatchView = true
                            this.isShowSearchResults = false
                            if(this.searchData === ''){
                                this.isShowSearchView = true
                            }else{
                                this.isShowSearchView = false
                            }
                        }else{
                            this.isShowNoMatchView = false
                            this.isShowSearchResults = true
                            if(this.searchData === ''){
                                this.isShowSearchView = true
                            }else{
                                this.isShowSearchView = false
                            }

                        }


                    } else {
                        this.searchResults = {
                            customers: null,
                            documents: null,
                            products: null,
                            purchase_order: null,
                            shipments: null,
                            suppliers: null
                        }
                    }
                    
                    // if (this.currentRouteName === 'POs' || this.currentRouteName == 'PO Details') {
                    //     this.setMasterSearchLoading(true)
                    //     this.poResults = await this.$store.dispatch('po/fetchPoGlobalSearch', passedData)
                    //     this.setMasterSearchLoading(false)

                    // } else if (this.currentRouteName === 'Products') {
                    //     this.productsCurrentPage = 1
                    //     this.setMasterSearchLoading(true)
                    //     this.productsSearchResults = await this.$store.dispatch('products/fetchProductsSearchGlobal', passedData)
                    //     this.setMasterSearchLoading(false)

                    //     if (typeof this.productsSearchResults !== 'undefined' && this.productsSearchResults !== null) {
                    //         if (typeof this.productsSearchResults.data !== 'undefined' && this.productsSearchResults.data !== null
                    //             && Array.isArray(this.productsSearchResults.data)) {
                    //             this.productsData = this.productsSearchResults.data

                    //             await this.loadMoreProducts()
                    //         }
                    //     }
                    // } else {
                    //     await this.fetchMasterSearch(passedData)

                    //     if (this.getAllMasterSearch !== 'undefined' && this.getAllMasterSearch !== null) {

                    //         if (this.getAllMasterSearch.documents.length !== 'undefined' && this.getAllMasterSearch.documents.length !== 0) {
                    //             this.searchResults.documents = this.getAllMasterSearch.documents
                    //         }
                    //         if (this.getAllMasterSearch.products.length !== 'undefined' && this.getAllMasterSearch.products.length !== 0) {
                    //             this.searchResults.products = this.getAllMasterSearch.products
                    //         }
                    //         if (this.getAllMasterSearch.purchase_order.length !== 'undefined' && this.getAllMasterSearch.purchase_order.length !== 0) {
                    //             this.searchResults.purchase_order = this.getAllMasterSearch.purchase_order
                    //         }
                    //         if (this.getAllMasterSearch.shipments.length !== 'undefined' && this.getAllMasterSearch.shipments.length !== 0) {
                    //             this.searchResults.shipments = this.getAllMasterSearch.shipments
                    //         }
                    //         if (this.getAllMasterSearch.suppliers.length !== 'undefined' && this.getAllMasterSearch.suppliers.length !== 0) {
                    //             this.searchResults.suppliers = this.getAllMasterSearch.suppliers
                    //         }

                    //     } else {
                    //         this.searchResults = {
                    //             customers: null,
                    //             documents: null,
                    //             products: null,
                    //             purchase_order: null,
                    //             shipments: null,
                    //             suppliers: null
                    //         }
                    //     }
                    // }

                } catch (e) {
                    throw Error(e)
                }
            } else {
                this.clear()
            }
        },
        closeIfClickedOutside(event) {
            if (typeof event !== 'undefined' && event !== 'undefined' && event !== null) {
                if (document.getElementById('dropdown').contains(event.target) !== null) {
                    if (!document.getElementById('dropdown').contains(event.target)) {
                        // document.getElementById("input-search-data").focus()
                        document.removeEventListener('click', this.closeIfClickedOutside)
                        console.log("again")
                        this.isOpen = false
                        this.showOpen = false
                        //this.searchData = ''
                        
                        this.addOverlayMutate(false);
                        if(this.searchData !== ''){
                            this.isShowSearchView = false
                            this.isShowSearchResults = false
                            this.searchResults = {
                                customers: null,
                                shipments: null,
                                suppliers: null,
                                products: null,
                                purchase_order: null,
                                documents: null
                            }
                            this.showOpen = true
                            this.inputBackgroundColor = 'white';
                            this.inputBorderStyle = '1px solid #E7E9EE';
                        }else{
                            this.inputBorderStyle = '';
                            this.inputBackgroundColor = '';
                        }
                    }
                }
            }
        },
        async goToShipmentDetails(data) {
            try {
                this.isOpen = false
                this.showOpen = false
                this.searchData = ''
                await this.fetchNewShipmentDetails(data.id)
                this.clear()
            } catch (e) {
                console.log(e);
            }
        },
        date(date) {
            return moment(date).format('MM/DD/YYYY');
        },
        async loadMoreProducts() {
            if (this.productsCurrentPage < this.productsSearchResults.last_page) {
                this.productsCurrentPage++

                try {
                    let passedData = {
                        cancelToken: new CancelToken(function executor(c) {
                            cancel = c
                        }),
                        params: {
                            search: this.searchData,
                            page: this.productsCurrentPage
                        }
                    }

                    let loadProducts = await this.$store.dispatch('products/fetchProductsSearchGlobal', passedData)

                    if (typeof loadProducts !== 'undefined' && loadProducts !== null &&
                        typeof loadProducts.data !== 'undefined' && Array.isArray(loadProducts.data) &&
                        loadProducts.data.length > 0) {

                        let newloaddata = loadProducts.data

                        this.productsData = [...this.productsData, ...newloaddata]

                        if (this.productsCurrentPage < this.productsSearchResults.last_page) {
                            this.loadMoreProducts()
                        }
                    } else {
                        this.productsData;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },
        openProductViewDialog(data) {
            let products = { ...data }

            if (!products.carton_dimensions) {
                products = {
                    ...products, carton_dimensions: {
                        l: 0,
                        w: 0,
                        h: 0,
                        uom: 'cm'
                    }
                }
            } else {
                let parseDimensions = JSON.parse(products.carton_dimensions)
                products = { ...products, carton_dimensions: parseDimensions }
            }

            if (!products.unit_dimensions) {
                products = {
                    ...products, unit_dimensions: {
                        l: 0,
                        w: 0,
                        h: 0,
                        uom: 'cm'
                    }
                }
            } else {
                let parseDimensions = JSON.parse(products.unit_dimensions)
                products = { ...products, unit_dimensions: parseDimensions }
            }

            if (!products.unit_weight) {
                products = {
                    ...products, unit_weight: {
                        l: 0,
                        w: 0,
                        h: 0,
                        uom: 'cm'
                    }
                }
            } else {
                let parseDimensions = JSON.parse(products.unit_weight)
                products = { ...products, unit_weight: parseDimensions }
            }

            this.editedProductItem = Object.assign({}, products)
            this.dialogViewProduct = true
            this.isOpen = false
            this.showOpen = false
            this.searchData = ''
        },
        closeProductViewDialog() {
            this.$nextTick(() => {
                this.editedProductItem = Object.assign({}, this.defaultProductItem)
                this.editedIndexProduct = 0
            })

            this.dialogViewProduct = false
        },
        editProduct(product) {
            this.editedIndexProduct = this.productsData.indexOf(product)

            if (this.editedIndexProduct == -1) {
                if (typeof product.id !== 'undefined') {
                    this.editedIndexProduct = _.findIndex(this.productsData, e => (e.id == product.id))
                }
            }

            let tempProduct = { ...product }
            if (!tempProduct.carton_dimensions) {
                tempProduct = {
                    ...tempProduct, carton_dimensions: {
                        l: 0,
                        w: 0,
                        h: 0,
                        uom: 'cm'
                    }
                }
            }

            if (!tempProduct.unit_dimensions) {
                tempProduct = {
                    ...tempProduct, unit_dimensions: {
                        l: 0,
                        w: 0,
                        h: 0,
                        uom: 'cm'
                    }
                }
            }

            if (!tempProduct.unit_weight) {
                tempProduct = {
                    ...tempProduct, unit_weight: {
                        value: 0,
                        unit: 'kg'
                    }
                }
            }

            if (!tempProduct.is_for_classification_code) {
                tempProduct = { ...tempProduct, is_for_classification_code: 0, userClassification: 1 }
            }

            if (!tempProduct.classification_code || tempProduct.classification_code === 'null') {
                tempProduct = { ...tempProduct, classification_code: '' }
            }

            if (!tempProduct.additional_classification_code || tempProduct.additional_classification_code === 'null') {
                tempProduct = { ...tempProduct, additional_classification_code: '' }
            }

            this.editedProductItem = { ...tempProduct }
            this.dialogAddProduct = true
        },
        closeProduct() {
            this.$nextTick(() => {
                this.editedProductItem = Object.assign({}, this.defaultProductItem)
                this.editedIndexProduct = 0
            })
            this.dialogAddProduct = false
        },
        deleteProductItem(item) {
            this.dialogDeleteProduct = true
            this.currentProductToDelete = item
        },
        async deleteProductConfirm() {
            if (this.currentProductToDelete !== null) {
                try {
                    let storePagination = this.$store.state.products.products
                    let page = typeof storePagination.current_page !== 'undefined' ? storePagination.current_page : 1

                    await this.deleteProduct(this.currentProductToDelete.id)

                    if (storePagination.products.length === 1 && storePagination.current_page !== 1) {
                        page = page - 1
                    }

                    this.notificationCustom('Product has been deleted.')
                    this.closeDeleteProduct()

                    await this.fetchProducts(page)
                } catch (e) {
                    this.closeDeleteProduct()
                    this.notificationError(e)
                }
            }
        },
        closeDeleteProduct() {
            this.dialogDeleteProduct = false
        },
        setToDefault() {
            this.editedProductItem = {
                sku: null,
                name: '',
                category_id: null,
                description: '',
                units_per_carton: '',
                image: null,
                classification_code: '',
                additional_classification_code: '',
                duty_rate: '',
                unit_price: 0,
                upc_number: '',
                carton_upc: '',
                is_for_classification_code: 1,
                userClassification: 0,
                country_from: '',
                country_to: '',
                product_classification_description: '',
                carton_dimensions: {
                    l: 0,
                    w: 0,
                    h: 0,
                    uom: 'cm'
                },
                unit_dimensions: {
                    l: 0,
                    w: 0,
                    h: 0,
                    uom: 'cm'
                },
                unit_weight: {
                    value: 0,
                    unit: 'kg'
                },
            }
        },
        onAutocompleteClick() {
            this.addStyle = true;
        },
        onAutocompleteChange() {
            this.addStyle = false;
            if(this.searchData != ''){
            this.apiCall(this.searchData)
            this.inputBackgroundColor = "#F9FAFB"
            }
        }
    },
    computed: {
        ...mapGetters({
            getAllMasterSearch: 'getAllMasterSearch',
            masterSearchLoading: 'masterSearchLoading',
            getDeleteProductLoading: 'products/getDeleteProductLoading',
            getAllCategoriesDropdown: 'category/getAllCategoriesDropdown'
        }),
        getBetaUrl() {
			let betaUrl = this.$store.getters["page/getBetaUrl"];
			betaUrl = betaUrl.replace("api", "");
			return betaUrl;
		},
        currentRouteName() {
            return this.$route.name
        },
        poGlobalSearchLoading() {
            return this.$store.getters['po/getPoGlobalSearchLoading']
        },
        isPoResultShow() {
            let isShow = false

            if (this.currentRouteName === 'POs' || this.currentRouteName === 'PO Details') {
                isShow = true
            }

            return isShow
        },
        isShipmentResultShow() {
            let isShow = false

            if (this.currentRouteName !== 'POs' || this.currentRouteName !== 'PO Details' || this.currentRouteName !== 'Products') {
                isShow = true
            }

            return isShow
        },
        isProductResultShow() {
            let isShow = false

            if (this.currentRouteName === 'Products') {
                isShow = true
            }

            return isShow
        },
        isShowEmptyResults() {
            let isShow = false

            if (!this.masterSearchLoading && this.searchResults) {
                
                if ((this.currentRouteName === 'POs' || this.currentRouteName === 'PO Details') && this.poResults.length === 0) {
                    isShow = true
                } else if (this.currentRouteName === 'Products' && this.productsData.length === 0) {
                    isShow = true
                } else if ((this.currentRouteName !== 'POs' &&
                    this.currentRouteName !== 'PO Details' &&
                    this.currentRouteName !== 'Products') &&
                    this.searchResults.customers === null &&
                    this.searchResults.shipments === null) {
                    isShow = true
                }
            }



            return isShow
        }
    },
    mounted() { },
    created() { },
    updated() {
        if (this.currentRouteName === 'Products') {
            if (typeof this.getAllCategoriesDropdown !== 'undefined') {
                this.categoryListData = this.getAllCategoriesDropdown
            }
        }
    }
};
</script>

<style>
@import '../assets/css/shipments_styles/searchGetHelp.css';

.searchHelp .global-search-text-fields.v-autocomplete.v-select.v-input--is-focused input {
    min-width: 12px !important;
    gap: 4px;
    height: 26px;
}
.searchHelp .global-search-text-fields.v-text-field .v-input__prepend-inner,.searchHelp .v-text-field .v-input__append-inner {
  width: 14px;
  min-height: 14px;
  font-size: 10px;
  /* max-height: 14px; */
}

.searchHelp .global-search-text-fields.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot,.searchHelp .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 3px 8px;
    min-width: 51px;
    width: fit-content !important;
    max-width: 110px !important;
    gap: 4px;
    height: 26px;
}
.searchHelp .global-search-text-fieldsnew.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot,.searchHelp .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 3px 8px;
    min-width: 51px;
    width: 130px;
    gap: 4px;
    height: 26px;
    background-color: #E1F4FF ;
}
.searchHelp .global-search-text-fields.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    padding: 8px 0;
    flex: 1 1;
    flex-wrap: nowrap;
}
.searchHelp .v-select__selection {
    max-width: 100%;
}
.global-search-for-bg-active::before{
    opacity: 0;
    color: #4A4A4A !important;
    background-color: rgba(245, 249, 252, 0)
}
.global-search-for-bg-active:hover::before{
    background-color: rgb(245, 249, 252, 0) !important;
    opacity: 0.;
    color: #4A4A4A !important;
}

.searchHelp .v-skeleton-loader .v-skeleton-loader__table-cell.v-skeleton-loader__bone {
	height: 32px;
}

.searchHelp .v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line.v-skeleton-loader__bone {
	height: 50px;
}
#input-search-data::placeholder {
    color: #D0D5DD !important;
}
</style>
